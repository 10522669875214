import type { FC } from 'react';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { ApolloError } from 'apollo-client';
import { FormattedMessage } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import Button from '@atlaskit/button/custom-theme-button';
import AddCommentIcon from '@atlaskit/icon/core/comment';
import Tooltip from '@atlaskit/tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';

import { useObjectSidebar } from '@confluence/object-sidebar-api';
import {
	VIEW_PAGE_CONTEXT_MENU_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { BannerStateContainer } from '@confluence/banners';
import { useCommentsContentState, useCommentsContentActions } from '@confluence/comment-context';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useSearchSessionId } from '@confluence/search-session';
import { DialogsStateContainer } from '@confluence/dialogs';

import { Popup } from './Popup';
import { getRange, findStickyHeader } from './highlightActionsUtils';
import {
	BuiltInActions,
	DisabledButtonWrapper,
	DisabledButtonOverlay,
} from './highlightActionsStyled';

export type SelectionRect = {
	top: number;
	left: number;
	width: number;
	height: number;
};

export type ActiveSelection = {
	selectedText: string;
	selectedRange: Range;
};

type HighlightActionsSimple = {
	contentId: string;
	isArchived?: boolean;
	contentStatusError?: ApolloError;
	isAnnotationAllowed?: boolean;
	range?: Range;
	onCommentButtonPress?: () => void;
	needTransition?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};

type Position = {
	top: number;
	left: number;
};

const MARGIN_FROM_NODE = 12;

export const HighlightActionsSimple: FC<HighlightActionsSimple> = ({
	contentId,
	isArchived,
	contentStatusError,
	range,
	isAnnotationAllowed,
	onCommentButtonPress,
	needTransition,
	onMouseEnter,
	onMouseLeave,
}) => {
	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [position, setPosition] = useState<Position | null>(null);
	const [{ searchSessionId, additionalAnalytics }] = useSearchSessionId();
	const [{ isObjectSidebarShown }, { hideObjectSidebar }] = useObjectSidebar();

	const mountedRef = useRef(false);
	const screenEventFiredRef = useRef(false);

	useLayoutEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);

	function getScrollParent(node: any): any {
		if (!node) {
			return null;
		}

		if (node.nodeType !== Node.TEXT_NODE && node.scrollWidth > node.clientWidth) {
			return node;
		} else {
			return getScrollParent(node.parentNode);
		}
	}

	function getParentNode(node: any): HTMLElement | null {
		if (!node) {
			return null;
		}

		if (node.nodeType !== Node.TEXT_NODE) {
			return node;
		} else {
			return getParentNode(node.parentNode);
		}
	}

	const getPosition = (range: Range) => {
		const contentRect = getParentNode(range.commonAncestorContainer)!.getBoundingClientRect();

		const portalContainer = document.querySelector(`#highlight-actions-portal-container`);

		const portalRect = portalContainer?.getBoundingClientRect() || {
			left: 0,
			top: 0,
		};

		const rect = range.getBoundingClientRect();

		const { left: leftRect, top, width } = rect;

		// Popup can't be rendered outside the body area
		let leftLimit = contentRect.left;
		let rightLimit = contentRect.right;

		// If the selected text has a parent that is scrollable for example like text inside table.
		// The popup is limited to be shown within the scrollable area.
		const firstParentThatCanScroll = getScrollParent(range.startContainer);
		if (firstParentThatCanScroll) {
			const scrollParentRect = firstParentThatCanScroll.getBoundingClientRect();
			leftLimit = Math.max(leftLimit, scrollParentRect.left);
			rightLimit = Math.min(rightLimit, scrollParentRect.right);
		}

		let x = width / 2 + leftRect;
		x = Math.max(leftLimit, Math.min(rightLimit, x));

		return {
			left: x - portalRect.left,
			top: top - portalRect.top + MARGIN_FROM_NODE,
		};
	};

	useEffect(() => {
		if (range) {
			const pos = getPosition(range);
			setPosition(pos);
		} else {
			setPosition(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [range]);

	const createReactInlineCommentHighlight = () => {
		if (isObjectSidebarShown) {
			hideObjectSidebar();
		}
		onCommentButtonPress && onCommentButtonPress();
	};

	const handleCommentButtonClick = (dialogs) => {
		if (hasContentChanged) {
			dialogs.showDialog(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					createReactInlineCommentHighlight();
				},
			});
		} else {
			createReactInlineCommentHighlight();
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'highlightActionsMenu',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createInlineCommentFromHighlightActionsMenu',
				type: 'ui',
				attributes: {
					contentId,
					pageMode: 'view',
					searchSessionId,
					...additionalAnalytics,
				},
			},
		}).fire();
	};

	const createInlineCommentButton = () => {
		const isHighlightValid = !isArchived && isAnnotationAllowed;

		const createButton = (
			<Subscribe to={[DialogsStateContainer]}>
				{(dialogs: DialogsStateContainer) => (
					<Button
						appearance="subtle"
						data-key="com.atlassian.confluence.plugins.confluence-inline-comments:create-inline-comment" // Needed for integration tests
						iconBefore={<AddCommentIcon label="" spacing="spacious" />}
						isDisabled={!isHighlightValid}
						testId="createInlineCommentButton"
						onClick={() => {
							handleCommentButtonClick(dialogs);
						}}
					>
						<FormattedMessage
							id="highlight-actions.inline-comments.comment"
							defaultMessage="Comment"
							description="Make a new comment"
						/>
					</Button>
				)}
			</Subscribe>
		);

		// WS-2802 - We need to wrap the disabled button and put a transparent absolute overlay over the button
		//           in order to show the tooltip correctly as the disabled button swallows all events on the element
		return isHighlightValid ? (
			<Fragment key="inline-comment-button">{createButton}</Fragment>
		) : (
			<Tooltip
				delay={0}
				position="top"
				content={
					!isArchived ? (
						fg('editor_inline_comments_on_inline_nodes') ? (
							<FormattedMessage
								id="highlight-actions.inline-comments.comment.create.disabled"
								description="Error message to communicate to the user they can only do the current action in certain contexts"
								defaultMessage="You can only comment on text, headings, emojis, dates, mentions, links, and statuses."
							/>
						) : (
							<FormattedMessage
								id="highlight-actions.inline-comments.comment.unsupported"
								description="Tooltip for the unsupported inline comment on the content that contains an emoji, a macro, or an @mention"
								defaultMessage="Comments can only be added to text that doesn’t contain an emoji, a macro, or an @mention"
							/>
						)
					) : (
						<FormattedMessage
							description="Tooltip for the unsupported inline comment for the archive page"
							id="highlight-actions.inline-comments.comment.unsupported.archive"
							defaultMessage="Comments can’t be added to archived items"
						/>
					)
				}
			>
				<DisabledButtonWrapper key="inline-comment-button">
					<DisabledButtonOverlay />
					{createButton}
				</DisabledButtonWrapper>
			</Tooltip>
		);
	};

	const renderPopup = (component) => (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				// if there's a sticky header we want to position the toolbar below
				// the nav, the page header (if visible) and the sticky header
				let stickyHeaderHeight = 0;
				const range = getRange();
				if (range) {
					const header = findStickyHeader(range.commonAncestorContainer);
					if (header && bannerState) {
						stickyHeaderHeight = bannerState.getTotalHeight() + header.clientHeight;
					}
				}

				if (!screenEventFiredRef.current) {
					createAnalyticsEvent({
						type: 'sendScreenEvent',
						data: {
							name: 'highlightActionsMenu',
							attributes: {
								contentId,
							},
						},
					}).fire();
				}
				screenEventFiredRef.current = true;

				return (
					<Fragment>
						{position && (
							<Popup
								top={stickyHeaderHeight + position.top}
								left={position.left}
								needTransition={needTransition}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								{component}
							</Popup>
						)}

						<ExperienceSuccess name={VIEW_PAGE_CONTEXT_MENU_EXPERIENCE} />
					</Fragment>
				);
			}}
		</Subscribe>
	);

	return (
		<Fragment>
			{contentStatusError && <ErrorDisplay error={contentStatusError} />}
			<ErrorBoundary attribution={Attribution.BACKBONE}>
				<ExperienceStart id={contentId} name={VIEW_PAGE_CONTEXT_MENU_EXPERIENCE} />
				{renderPopup(
					<BuiltInActions key="built-in-actions">{createInlineCommentButton()}</BuiltInActions>,
				)}
			</ErrorBoundary>
		</Fragment>
	);
};
