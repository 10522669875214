import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';

import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { useInlineCommentQueryParams } from '@confluence/comment';
import {
	checkElementExpandInEditor,
	scrollToElementWithViewingRoom,
} from '@confluence/comments-util';
import { getLogger } from '@confluence/logger';
import { markErrorAsHandled } from '@confluence/graphql';
import { usePageContentId } from '@confluence/page-context';
import { ResolvedInlineCommentsDialogLoader } from '@confluence/resolved-inline-comments-dialog';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { useShowCommentsPanel } from '@confluence/comments-panel';
import { ViewValues } from '@confluence/comments-panel-utils';
import { fg } from '@confluence/feature-gating';

import { InlineCommentQueryParamsQuery } from './graphql/InlineCommentQueryParamsQuery.graphql';
import type {
	InlineCommentQueryParamsQuery as InlineCommentQueryParamsQueryData,
	InlineCommentQueryParamsQueryVariables,
	InlineCommentQueryParamsQuery_comments_nodes_location_InlineComment as InlineCommentLocation,
} from './graphql/__types__/InlineCommentQueryParamsQuery';

const logger = getLogger('inline-comments:query-param-listener');

type InlineCommentQueryParamsListenerProps = {
	isArchived: boolean;
};

type QueryParamData =
	| {
			location?: InlineCommentLocation;
			parentId?: string | null;
			parentLocation?: InlineCommentLocation;
	  }
	| undefined;

export const InlineCommentQueryParamsListener: React.FC<InlineCommentQueryParamsListenerProps> = ({
	isArchived,
}) => {
	const [isResolveDialogShown, setIsResolveDialogShown] = useState(false);
	const { showCommentsPanel } = useShowCommentsPanel();
	const { showModal } = useDialogs();
	const [contentId] = usePageContentId();
	const { focusedCommentId, replyToCommentId, editCommentId, removeCommentQueryParams } =
		useInlineCommentQueryParams({
			queryArchivedContent: isArchived,
		});

	const commentId = focusedCommentId || replyToCommentId || editCommentId;

	const { data, loading, error } = useQuery<
		InlineCommentQueryParamsQueryData,
		InlineCommentQueryParamsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InlineCommentQueryParamsQuery,
		{
			variables: {
				commentId: commentId!,
			},
			skip: !commentId,
		},
	);

	if (error) {
		logger.error`An error occurred when querying the focused comment status; ${error}`;

		// TODO: If this query fails, there isn't much we can do, so just track it with experience tracker?

		markErrorAsHandled(error);
	}

	const queryParamData: QueryParamData = useMemo(() => {
		if (!error && !loading && data) {
			const location = data.comments?.nodes?.[0]?.location as InlineCommentLocation | undefined;
			const parentId = data.comments?.nodes?.[0]?.parentId;
			const parentLocation = data.comments?.nodes?.[0]?.ancestors?.[0]?.location as
				| InlineCommentLocation
				| undefined;

			return {
				location,
				parentId,
				parentLocation,
			};
		}

		return undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, commentId, data, loading, error]);

	useEffect(() => {
		if (!queryParamData) {
			return;
		}

		const { location, parentId, parentLocation } = queryParamData;

		if (location) {
			let annotationId = location.inlineMarkerRef;
			let isResolved = Boolean(location.inlineResolveProperties?.resolved);

			if (!annotationId) {
				// This is a child comment, find the parent and use its markerRef and resolved state

				if (parentLocation) {
					annotationId = parentLocation.inlineMarkerRef;
					isResolved = Boolean(parentLocation.inlineResolveProperties?.resolved);
				}
			}

			if (!isResolved) {
				const mark = document.querySelector(`[data-id="${annotationId}"]`) as HTMLElement | null;

				if (mark) {
					let focusedMarkerRef = document.querySelector("[data-has-focus='true']");

					// If the already open container is showing the correct comment, don't send another click event to open it
					if (focusedMarkerRef?.id !== annotationId) {
						const emitter = getRendererAnnotationEventEmitter();

						setTimeout(() => {
							checkElementExpandInEditor(mark);
							// Excerpt(nested renderer) like macros re-render this component making the
							// highlight to be removed from the DOM
							// so, need to check the focus attribute once the ON_ANNOTATION_CLICK gets triggered
							focusedMarkerRef = document.querySelector("[data-has-focus='true']");

							if (
								!focusedMarkerRef &&
								emitter.emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
									annotationIds: [annotationId],
									eventTarget: mark,
								})
							) {
								emitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
									annotationId,
								});
							}
							// We need the timeout to give the document the chance to make the marks properly
						}, 1000);
					}
				}

				// if the comment is a reply, don't scroll to it here, we'll handle the reply scrolling after the reply has rendered to avoid double scrolling
				if (!parentId && mark) {
					// note that this is for Renderer for now
					scrollToElementWithViewingRoom({
						element: mark,
						useInstantScroll: true,
					});
				}
			} else {
				if (fg('confluence-frontend-comments-panel')) {
					showCommentsPanel({ openWithView: ViewValues.RESOLVED });
				} else {
					if (!isResolveDialogShown) {
						setIsResolveDialogShown(true);
						// @ts-expect-error - Incompatible types but works fine
						showModal(ResolvedInlineCommentsDialogLoader, {
							context: 'renderer',
							contentId: contentId!,
							focusedCommentData: {
								id: commentId,
								parentCommentId: parentId,
							},
							onClose: () => {
								removeCommentQueryParams();
								setIsResolveDialogShown(false);
							},
						});
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParamData, isResolveDialogShown]);

	return null;
};
